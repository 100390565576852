<template>
<div class="content-wrapper">

  <div v-if="isList" class="content pt-0">
    <bo-page-title />
    <div class="card">
      <div class="card-header bg-white">
        <div class="row align-items-center">
          <div class="col-md-5">
            <div class="form-row align-items-center">
              <div class="col-md-4">
                <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
              </div>
              <div class="col-md-8">
                <div class="form-group mb-0">
                  <div class="input-group">
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'" :ranges="datePickerConfig.ranges" @update="updateValues">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-header">
        <div class="row">
          <div class="col-lg-9">
            <div class="row gx-1">
              <div class="col-md">
                <div class="row gx-1">
                  <div class="col-md-auto">
                    <div class="form-group mb-0">
                      <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i class="icon-spinner11"></i></button>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan" :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk" :options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-6 col-lg-4">
                      <b-form-group class="mb-0">
                          <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_ranap"  :options="Config.mr.statusRanapPasienSelesai" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
                      </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
              <input class="form-control" v-model="filter.search" @input="doSearch" placeholder="Ketik Nama/No. RM Pasien" />
              <div class="form-control-feedback">
                <i class="icon-search4 text-indigo"></i>
              </div>

              <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn" @click="doResetData()">Reset</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
          <thead>
            <tr>
              <th>NO.</th>
              <th>NO. KEDATANGAN</th>
              <th>TANGGAL MASUK</th>
              <th>NAMA/NO KTP</th>
              <th>DOKTER DPJP</th>
              <th>NO RM</th>
              <th>RUANGAN</th>
              <th>JENIS RAWAT INAP</th>
              <th>TANGGAL LAHIR</th>
              <th>USIA</th>
              <th>PPJP</th>
              <th>STATUS</th>
              <th>AKSI</th>
            </tr>
          </thead>

          <tbody v-if="(dataList||[]).length">
            <template v-for="(v,k) in (dataList||[])">
              <tr :key="k">
                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                <td>
                  <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                </td>
                <td>
                  <div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
                </td>
                <td>
                  <div>
                    <a href="javascript:;" @click="toMonitoring(v.aranr_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover title="Periksa Pasien"> {{v.ap_fullname||"-"}}</a>
                    <br>
                    {{v.ap_code||"-"}}</div>
                  <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                </td>
                <td>{{v.bu_full_name||"-"}}</td>
                <td>
                  <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                  <span>dari {{v.aranres_cara_masuk == 'RAJAL'?'POLI': v.aranres_cara_masuk == 'BBL' ? 'BBL':'UGD'}}</span>
                  <div class="text-success-700 font-weight-semibold">
                    {{v.no_reg||"-"}}
                  </div>
                </td>
                <td>
                  <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                  <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                </td>
                <td>
                  <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
                </td>
                <td>
                  <div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
                  <span v-else> - </span>
                </td>
                <td>
                  <div class="font-weight-semibold">
                    {{v.ap_usia_with_ket || "-"}}
                  </div>
                </td>
                <td>
                  {{v.nama_ppjp||"-"}}
                </td>
                <td>
                  <div v-if="!v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan PPJP</div>
                  <div v-else-if="v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan Perawat Pelaksana</div>
                  <template v-else>
                    <div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div> <br />

                    <div class="badge badge-danger mb-1">{{ v.arcptlsp_type }}</div>
                  </template>

                </td>
                <td>
                  <router-link :to="{ name: 'RanapResumeMedis', params: { pageSlug: v.aranr_id } }" v-if="moduleRole('resume_medis')" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.right title="Resume Medis">
                    <i class="icon-pencil4"></i>
                  </router-link>

                  <a href="javascript:;" @click="downloadResumeMedis(v)" v-if="v.arcptlsp_is_finish == 'Y' && v.aranr_is_verifed_resume_medis == 'Y' && moduleRole('download_template')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Download Resume Medis"><i class="icon-download"></i></a>

                  <a href="javascript:;" @click="readmittedPasien(v)" v-if="moduleRole('pasien_dirawat_kembali')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Pasien Dirawat Kembali"><i class="fa-solid fa-bed-pulse"></i></a>

                  <a href="javascript:;" @click="openDetail(v)" v-if="moduleRole('view')" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover title="Lihat Detail"><i class="icon-file-eye"></i></a>
                </td>

              </tr>
            </template>
          </tbody>
          <tbody v-if="data.data.length == 0">
            <tr>
              <td colspan="99">
                <div class="text-center">
                  <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!data.data">
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
          </tbody>
        </table>

        <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
          <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-left"></i>
          </a>
          <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-right"></i>
          </a>
        </div>
      </div>

      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
        <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
      </b-card-footer>

    </div>

    <validation-observer ref="VFormSubmitPerawatPelaksana">
      <b-modal v-model="openPerawatPelaksana" @ok.prevent="submitPerawatPelaksana" size="lg" no-close-on-backdrop class="text-center" :title="'Tugaskan Perawat Pelaksana'">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th colspan="6">Informasi Pasien</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Nama</h4>
                  <p>{{rowEdit.ap_fullname||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Tanggal Lahir / Umur</h4>
                  <p>{{rowEdit.ap_dob | moment("DD MMMM YYYY") }} / {{rowEdit.ap_usia}} Tahun</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Jenis Kelamin</h4>
                  <p>{{rowEdit.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Ruangan</h4>
                  <p>{{rowEdit.mranb_name||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Kamar / No. Tempat Tidur / Kelas</h4>
                  <p>{{rowEdit.mranbed_name||"-"}} / {{rowEdit.mrankel_name||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Kelas Titipan</h4>
                  <p>-</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Jenis Cara Bayar</h4>
                  <p>{{getConfigDynamic(Config.mr.statusJaminan,rowEdit.aranres_jaminan)||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Alergi</h4>
                  <ul v-if="(rowEdit.alergi||[]).length" class="pl-3">
                    <li v-for="(v,k) in (rowEdit.alergi||[])" :key="k+'alergi'">
                      {{v.name||"-"}} - <span class="font-weight-semibold">{{v.jenis||"-"}}</span>
                    </li>
                  </ul>
                  <span v-else>Tidak ada Alergi</span>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Diagnosa Masuk</h4>
                  <p>{{rowEdit.diagnosa||"-"}}</p>
                </div>
              </td>
            </tr>
          </tbody>

          <thead>
            <tr>
              <th colspan="6">Penugasan Perawat Pelaksana</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="3" width="50%">
                <div>
                  <label for="selPppjp">Pilih Perawat Penanggung jawab Pasien (PPJP)</label>
                  <v-select placeholder=" -- Pilih Perawat Penanggung jawab Pasien -- " 
                  v-model="rowEdit.aranr_ppjp" :clearable="true" :options="mPPJP" label="text" :reduce="v=>v.value">
                      <template slot="selected-option" slot-scope="option">
                      <span :title="option.text+' - menangani '+option.pasien + ' Pasien'" v-b-tooltip.hover.right>{{ option.text }}</span>
                      </template>
                      <template slot="option" slot-scope="option">
                      <span :title="option.text+' - menangani '+option.pasien + ' Pasien'" v-b-tooltip.hover.right>{{ option.text }} - menangani 
                          <span class="font-weight-semibold"> {{option.pasien}} Pasien </span></span>
                      </template>
                  </v-select>
                </div>
                <VValidate :name="'Perawat Penanggung Jawab Pasien'" v-model="rowEdit.aranr_perawat_pelaksana" :rules="{required : 1}" />
              </td>
              <td colspan="3" width="50%">
                <div></div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-modal>
    </validation-observer>

    <b-modal id="detailData" size="xl" title="Detail" ok-only ok-title="Tutup">
      <div class="form-row">
        <div class="col-md-6">
          <table class="table table-bordered table-sm">
            <tr>
              <td width="50%">
                <div class="result_tab">
                  <h4>Tgl. Masuk</h4>
                  <p>{{rowReg.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}} WIB</p>
                </div>
              </td>
              <td width="50%">
                <div class="result_tab">
                  <h4>Tgl. Keluar</h4>
                  <p>{{rowReg.aranr_keluar_ranap_at | moment("DD MMMM YYYY, HH:mm")}} WIB</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Alasan Masuk RS</h4>
                  <p>{{rowResumeMedis.aranresm_hospitalization_reason||"-"}}</p>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Alergi</h6>
            </div>
            <div class="card-body p-3">
              <div class="form-row" v-if="rowResumeMedis.aranresm_has_alergi == 'Y'">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr v-for="(v,k) in (rowResumeMedis.aranresm_alergi||[])" :key="k+'ob'">
                          <td>
                            <div class="result_tab">
                              {{v.jenis||"-"}}
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              {{v.name||"-"}}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="form-row" v-else>
                <div class="col-md-12">
                  <span>Tidak Ada Alergi</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
        </div>
        <div class="card-body p-3">
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Tekanan Darah</h4>
                      <p v-if="rowResumeMedis.aranresm_sistolik && rowResumeMedis.aranresm_diastolik">{{rowResumeMedis.aranresm_sistolik||"-"}}/
                        {{rowResumeMedis.aranresm_diastolik}} mmHG</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Nadi</h4>
                      <p v-if="rowResumeMedis.aranresm_nadi">{{rowResumeMedis.aranresm_nadi}}x/mnt <span v-if="rowResumeMedis.aranresm_nadi_type">- {{rowResumeMedis.aranresm_nadi_type||"-"}}</span>
                      </p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Gula Darah</h4>
                      <p v-if="rowResumeMedis.aranresm_gda">{{rowResumeMedis.aranresm_gda}}mg/dL</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Pernafasan</h4>
                      <p v-if="rowResumeMedis.aranresm_pernafasan">{{rowResumeMedis.aranresm_pernafasan}}x/mnt</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Suhu</h4>
                      <p v-if="rowResumeMedis.aranresm_suhu">{{rowResumeMedis.aranresm_suhu}} &deg;C</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Kesadaran</h4>
                      <template v-if="tingkatKesadaranTotal">
                        <div class="">
                          <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                          <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span>
                          <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                          <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                          <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                          <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                          <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                          <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                        </div>

                        <div class="">
                          <span class="font-weight-semibold mr-3">E : {{rowResumeMedis.aranresm_gcs_response_mata||"-"}}</span>
                          <span class="font-weight-semibold mr-3">M : {{rowResumeMedis.aranresm_gcs_response_motorik||"-"}}</span>
                          <span class="font-weight-semibold mr-3">V : {{rowResumeMedis.aranresm_gcs_response_verbal||"-"}}</span>
                        </div>
                      </template>
                      <p v-else> - </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
        </div>
        <div class="card-body p-3">
          <table class="table table-bordered">
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Kepala</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_kepala == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_kepala == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_kepala_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Mata</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_mata == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_mata == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_mata_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Hidung</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_hidung == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_hidung == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_hidung_text||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Rambut</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_rambut == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_rambut == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_rambut_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Bibir</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_bibir == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_bibir == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_bibir_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Gigi Geligi</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_gigi_geligi == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_gigi_geligi == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_gigi_geligi_text||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Lidah</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_lidah == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_lidah == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_lidah_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Langit-Langit</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_langit_langit == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_langit_langit == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_langit_langit_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Leher</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_leher == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_leher == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_leher_text||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Tenggorokan</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_tenggorokan == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_tenggorokan == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_tenggorokan_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Tonsil</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_tonsil == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_tonsil == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_tonsil_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Dada</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_dada == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_dada == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_dada_text||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Payudara</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_payudara == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_payudara == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_payudara_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Punggung</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_punggung == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_punggung == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_punggung_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Perut</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_perut == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_perut == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_perut_text||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Genital</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_genital == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_genital == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_genital_text||"-"}}</p>
                </div>
              </td>

              <td width="33%">
                <div class="result_tab">
                  <h4>Anus</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_anus == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_anus == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_anus_text||"-"}}</p>
                </div>
              </td>

              <td width="33%">
                <div class="result_tab">
                  <h4>Lengan Atas</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_lengan_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_lengan_atas == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_lengan_atas_text||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Lengan Bawah</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_lengan_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_lengan_bawah == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_lengan_bawah_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Jari Tangan</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_jari_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_jari_tangan == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_jari_tangan_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Kuku Tangan</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_kuku_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_kuku_tangan == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_kuku_tangan_text||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Sendi Tangan</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_persendian_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_persendian_tangan == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_persendian_tangan_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Tungkai Atas</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_tungkai_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_tungkai_atas == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_tungkai_atas_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Tungkai Bawah</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_tungkai_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_tungkai_bawah == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_tungkai_bawah_text||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Jari Kaki</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_jari_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_jari_kaki == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_jari_kaki_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Kuku Kaki</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_kuku_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_kuku_kaki == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_kuku_kaki_text||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Sendi Kaki</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_persendian_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_persendian_kaki == 'TP'">Tidak Diperiksa</p>
                  <p v-else>{{rowResumeMedis.aranresm_ku_persendian_kaki_text||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <div class="result_tab">
                  <h4>Saraf</h4>
                  <p v-if="rowResumeMedis.aranresm_ku_saraf == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="rowResumeMedis.aranresm_ku_saraf == 'TP'">Tidak Diperiksa</p>
                  <p v-else> - </p>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="card border mb-3">
        <div v-b-toggle.diagnosa-dokter class="card-header bg-info" style="cursor: pointer;">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="card-title font-weight-semibold">Lihat Hasil Diagnosa &amp; Tindakan Oleh Dokter</h6>
            <i class="icon-chevron-down"></i>
          </div>
        </div>
  
        <b-collapse id="diagnosa-dokter">
          <div class="card-body">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(v,k) in dataICD10Dok" :key="k+'diagnosaDok2'">
                  <td>
                    <div class="result_tab">
                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                      <p>{{v.mi10_code||"-"}} - {{v.mi10_name||"-"}}</p>
                    </div>
                  </td>
                </tr>

                <tr class="bg_head_panel">
                  <td>
                    <h6 class="font-weight-bold mb-0">Diagnosa Lainnya</h6>
                  </td>
                </tr>

                <tr v-for="(v,k) in dataICD10DokLainnya" :key="k+'diagnosaDokLainnya'">
                  <td>
                    <div class="result_tab">
                      <h4>Diagnosis #{{k+1}}</h4>
                      <p>{{v.text||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-if="!(dataICD10Dok||[]).length">Tidak Ada Data</span>
          </div>

          <div class="card-body">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(v,k) in dataICD9Dok" :key="k+'diagnosaTindakan2'">
                  <td>
                    <div class="result_tab">
                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                      <p>{{v.mi9_code||"-"}} - {{v.mi9_name||"-"}}</p>
                    </div>
                  </td>
                </tr>

                <tr class="bg_head_panel">
                  <td>
                    <h6 class="font-weight-bold mb-0">Tindakan Lainnya</h6>
                  </td>
                </tr>

                <tr v-for="(v,k) in dataICD9DokLainnya" :key="k+'diagnosaTindakanLainnya'">
                  <td>
                    <div class="result_tab">
                      <h4>Tindakan #{{k+1}}</h4>
                      <p>{{v.text||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-if="!(dataICD9Dok||[]).length">Tidak Ada Data</span>
          </div>
        </b-collapse>
      </div>

      <div class="card border mb-3">
        <div v-b-toggle.diagnosa-rm class="card-header bg-info" style="cursor: pointer;">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="card-title font-weight-semibold">Lihat Hasil Diagnosa & Tindakan Oleh Rekam Medis</h6>
            <i class="icon-chevron-down"></i>
          </div>
        </div>
  
        <b-collapse id="diagnosa-rm">
          <div class="card-body">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(v,k) in dataICD10RM" :key="k+'diagnosaRM'">
                  <td>
                    <div class="result_tab">
                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                      <p>{{v.mi10_code||"-"}} - {{v.mi10_name||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-if="!(dataICD10RM||[]).length">Tidak Ada Data</span>
          </div>

          <div class="card-body">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(v,k) in dataICD9RM" :key="k+'diagnosaTindakanRM'">
                  <td>
                    <div class="result_tab">
                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                      <p>{{v.mi9_code||"-"}} - {{v.mi9_name||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-if="!(dataICD9RM||[]).length">Tidak Ada Data</span>
          </div>
        </b-collapse>
      </div>

      <div class="card border mb-3">
        <div v-b-toggle.diagnosa-upla class="card-header bg-info" style="cursor: pointer;">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="card-title font-weight-semibold">Lihat Hasil Diagnosa & Tindakan Oleh UPLA</h6>
            <i class="icon-chevron-down"></i>
          </div>
        </div>
  
        <b-collapse id="diagnosa-upla">
          <div class="card-body">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(v,k) in dataICD10" :key="k+'diagnosa'">
                  <td>
                    <div class="result_tab">
                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                      <p>{{v.mi10_code||"-"}} - {{v.mi10_name||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-if="!(dataICD10||[]).length">Tidak Ada Data</span>
          </div>

          <div class="card-body">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(v,k) in dataICD9" :key="k+'diagnosaTindakan'">
                  <td>
                    <div class="result_tab">
                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                      <p>{{v.mi9_code||"-"}} - {{v.mi9_name||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-if="!(dataICD9||[]).length">Tidak Ada Data</span>
          </div>
        </b-collapse>
      </div>

      <table class="table table-sm table-bordered">
        <tr>
          <td class="align-top" width="50%">
            <div class="result_tab">
              <h4>Pemeriksaan Penunjang</h4>
              <table class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <td></td>
                    <th>Hasil Pemeriksaan</th>
                    <th>Hasil</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v,k) in tindakan.lab">
                    <tr v-for="(dtHasil, keyHasil) in v.arantl_hasil" :key="`lkh-${k}-${keyHasil}`" :id="k">
                      <td :rowspan="v.arantl_hasil.length == 1 ? 1 : v.arantl_hasil.length" v-if="keyHasil == 0">
                        <label>
                          Laboratorium

                          <ul class="pl-3">
                            <li v-for="(dtTindakan, keyTindakan) in v.arantl_tindakan.split(', ')" :key="`atin-${keyTindakan}`">
                              {{ dtTindakan }}
                            </li>
                          </ul>
                        </label>
                      </td>
                      <td>
                        {{ dtHasil.value }}
                      </td>
                      <td>
                        <a :href="uploader(dtHasil.file)" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-if="dtHasil.file">
                          <i class="icon-file-eye2"></i>
                        </a>
                      </td>
                    </tr>
                  </template>

                  <tr v-if="!(tindakan.lab||[]).length">
                    <td>
                      <label>
                        Laboratorium
                      </label>
                    </td>
                    <td>
                      <span>Tidak ada pemeriksaan</span>
                    </td>
                    <td>
                    </td>
                  </tr>

                  <template v-for="(v,k) in tindakan.radiologi">
                    <tr v-for="(dtHasil, keyHasil) in v.arantr_hasil" :key="`rkh-${k}-${keyHasil}`" :id="k">
                      <td :rowspan="v.arantr_hasil.length == 1 ? 1 : v.arantr_hasil.length" v-if="keyHasil == 0">
                        <label>
                          Radiologi

                          <ul class="pl-3">
                            <li v-for="(dtTindakan, keyTindakan) in v.arantr_tindakan.split(', ')" :key="`ratin-${keyTindakan}`">
                              {{ dtTindakan }}
                            </li>
                          </ul>
                        </label>
                      </td>
                      <td>
                        {{ dtHasil.value }}
                      </td>
                      <td>
                        <a :href="uploader(dtHasil.file)" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-if="dtHasil.file">
                          <i class="icon-file-eye2"></i>
                        </a>
                      </td>
                    </tr>
                  </template>
                  <tr v-if="!(tindakan.radiologi||[]).length">
                    <td>
                      <label>
                        Radiologi
                      </label>
                    </td>
                    <td>
                      <span>Tidak ada pemeriksaan</span>
                    </td>
                    <td>
                    </td>
                  </tr>

                  <tr v-for="(v, k) in tindakan.fisio" :key="`tf-${k}`">
                    <td>
                      <label>
                        Fisioterapi
                      </label>

                      <ul class="pl-3">
                        <li v-for="(dtTindakan, keyTindakan) in [...(v.arantf_elektroterapi||[]), ...(v.arantf_mekanoterapi||[]), ...(v.arantf_latihan||[])]" :key="`fatin-${keyTindakan}`">
                          <span v-if="dtTindakan == 'LE'">
                            {{v.arantf_elektroterapi_text||"-"}}</span>
                          <span v-else-if="dtTindakan == 'LM'">
                            {{v.arantf_mekanoterapi_text||"-"}}</span>
                          <span v-else-if="dtTindakan == 'LL'">
                            {{v.arantf_latihan_text||"-"}}</span>
                          <span v-else>{{ dtTindakan }}</span>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <span>Tidak ada pemeriksaan</span>
                    </td>
                    <td></td>
                  </tr>
                  <tr v-if="!(tindakan.fisio||[]).length">
                    <td>
                      <label>
                        Fisioterapi
                      </label>
                    </td>
                    <td>
                      <span>Tidak ada pemeriksaan</span>
                    </td>
                    <td>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label>
                        Lain-lain
                      </label>
                    </td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </td>
          <td class="align-top" width="50%">
            <div class="result_tab">
              <h4>Konsultasi/Pengobatan</h4>
              <p>{{rowResumeMedis.aranresm_consultation||"-"}}</p>
            </div>
          </td>
        </tr>
        <tr>
          <td class="align-top">
            <div class="result_tab">
              <h4>Keadaan Keluar</h4>
              <p>{{rowResumeMedis.aranresm_keadaan_keluar||"-"}}</p>
            </div>
          </td>
          <td class="align-top">
            <div class="result_tab">
              <h4>Cara Keluar</h4>
              <p>{{rowResumeMedis.aranresm_cara_keluar||"-"}}</p>
            </div>
          </td>
        </tr>
        <tr>
          <td class="align-top">
            <div class="result_tab">
              <h4>Tindak Lanjut</h4>
              <p>{{rowResumeMedis.aranresm_tindak_lanjut||"-"}}</p>
            </div>
          </td>
          <td class="align-top">
            <div class="result_tab">
              <h4>Anjuran/Edukasi</h4>
              <p>{{rowResumeMedis.aranresm_reccomendation||"-"}}</p>
            </div>
          </td>
        </tr>
      </table>
    </b-modal>
  </div>

</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const moment = require('moment')

// import Datepicker from 'vuejs-datepicker'

const _ = global._
import $ from 'jquery'
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  components: {
    DateRangePicker,
  },
  computed: {
    isDetail() {
      return this.$route.query.isDetail
    },
    isDoc() {
      return this.$route.query.isDoc
    },
    passToSub() {
      return {
        Config: this.Config,
        mrValidation: this.mrValidation,
        rowPasien: this.rowPasien,
        isShowBangsal: this.isShowBangsal,
        infoBangsal: this.infoBangsal,
        katalogBangsal: this.katalogBangsal
      }
    },
    tingkatKesadaranTotal() {
      let total = ((this.rowResumeMedis.aranresm_gcs_response_mata || 0) + (this.rowResumeMedis.aranresm_gcs_response_verbal || 0) + (this.rowResumeMedis.aranresm_gcs_response_motorik || 0))
      return total
    },
  },
  data() {
    return {
      idKey: 'aranres_id',
      datePickerConfig: {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
        autoApply: true,
        ranges: {
          'Hari Ini': [new Date(), new Date()],
          '7 Hari Terakhir': [new Date(moment().subtract(7, 'days')), new Date()],
          '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
          applyLabel: 'Terapkan',
          cancelLabel: 'Batal',
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
        }
      },
      dateRange: {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
      },

      rowEdit: {},
      rowPasien: {},
      rowAdditional: {},
      rowReg: {},
      rowResumeMedis: {},

      dataICD9: [],
      dataICD9Dok: [],
      dataICD9DokLainnya: [],
      dataICD9RM: [],

      dataICD10: [],
      dataICD10Dok: [],
      dataICD10DokLainnya: [],
      dataICD10RM: [],

      tindakan: [],

      mRanapBangsal: [],
      mPPJP: [],
      openPerawatPelaksana: false
    }
  },
  mounted() {
    this.filter.status_jaminan = 'ALL'
    this.filter.cara_masuk = 'ALL'
    this.filter.ranap_bangsal = this.user.bu_bangsal_id
    this.filter.status_ranap = 3

    this.doConvertDate()
    this.apiGet()

    if (this.isList) {
      this.apiGetResume()
    }

    setTimeout(() => {
      this.setSlide()
    }, 1000)
  },
  methods: {
    openDetail(data) {
      Gen.apiRest(
        "/get/" + `RanapUPLA/${data.aranr_id}`, {},
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$bvModal.show("detailData")
      })
    },
    readmittedPasien(data) {
      this.$swal.fire({
        icon: 'warning',
        title: 'Apakah Anda yakin pasien akan dirawat kembali?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        allowOutsideClick: false,
      }).then(btn => {
        if (btn.isConfirmed) {
          this.$swal.fire({
            title: 'Processing...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: {
                type: "rawat-kembali-pasien",
                id_cppt_tindak_lanjut: data.arcptlsp_id,
                id_registrasi: data.aranr_id,
              }
            },
            "POST"
          ).then(res => {
            this.$swal.close()

            this.$swal.fire({
              title: "Pasien berhasil dirawat kembali",
              icon: "success",
            }).then(() => {
              this.apiGet()
              this.apiGetResume()
            })
          }).catch(err => {
            this.$swal.close()

            if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
            }

            this.doSetAlertForm(err)
          })
        }
      })
    },
    downloadResumeMedis(data) {
      this.$swal.fire({
        title: 'Processing...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
          "/do/" + 'RanapResumeMedis', {
            data: {
              ...data,
              type: "download-resume-medis",
              id_registrasi: data.aranr_id
            }
          },
          "POST"
        )
        .then(res => {
          const linkSource = res.data
          const downloadLink = document.createElement("a")
          downloadLink.href = linkSource
          downloadLink.download = `Resume-Medis-${new Date().getTime()}.pdf`
          downloadLink.click()

          this.$swal.close()
        })
        .catch(() => {
          this.$swal.close()
          // this.globalVar.apiGenError( err.response.data )
        })
    },
    toMonitoring(regID) {
      this.$router.push({
        name: 'RanapMonitoring',
        params: {
          pageSlug: regID
        }
      }).catch(() => {})
    },
    submitPerawatPelaksana() {
      this.$refs['VFormSubmitPerawatPelaksana'].validate().then(success => {
        if (!success) {
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          })
        } else {
          this.loadingOverlay = true
          let data = this.rowEdit
          data.type = 'assign-perawat-pelaksana'

          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: data
            },
            "POST"
          ).then(res => {
            let resp = res.data
            this.loadingOverlay = false
            this.$swal({
              title: resp.message,
              icon: "success",
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              if (result.value) {
                this.openPerawatPelaksana = false
                this.apiGet()
              }
            })
          })
        }
      })
    },
    tugasKanPPJP(v) {
      this.rowEdit = v
      let data = this.rowEdit
      data.type = 'get-data-alergi-diagnosa'
      this.loadingOverlay = true

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.openPerawatPelaksana = true
        this.loadingOverlay = false
        this.rowEdit.alergi = resp.alergi
        this.rowEdit.diagnosa = resp.diagnosa

      })

    },
    setSlide() {
      const responsiveTable = document.querySelector('.table-responsive')
      if (responsiveTable) {
        const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
        const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
        if (responsiveTableScroll) {
          // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
          Array.from(responsiveTableScrollActions).forEach(action => {
            action.addEventListener('click', e => {
              const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100

              responsiveTable.scroll({
                left: scrollAmount,
                behavior: 'smooth'
              })
            })
          })
        }
      }
    },
    doResetData() {
      this.doReset()
      this.dateRange = {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
      }
      this.doConvertDate()
    },
    doRefreshData() {
      this.apiGet()
      window.scrollTo(0, 0)
    },
    apiGetResume() {
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      Gen.apiRest(
        "/get/" + this.modulePage + '/resume', {
          params: Object.assign({}, paramsQuery || {})
        }
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },
    doConvertDate() {
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doPageOne() {
      this.filter.page = 1
    },
    doFill() {
      this.doPageOne()
      this.doFilter()
    },
    updateValues(e) {
      this.doConvertDate()
      this.doFill()
    },
    doSearch: _.debounce(function () {
      this.doPageOne()
      this.doFilter()
    }, 500),
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },
    to24Hours(date) {
      return moment(date).add(24, 'hours').format('YYYY-MM-DD')
    },
    validationTime(regDate) {
      if (moment().format('YYYY-MM-DD') >= this.to24Hours(regDate)) {
        return true
      } else {
        return false
      }
    },
    initKajianAwalDokter(row) {
      this.createKajianAwal(row)
    },
    createKajianAwal(row) {
      this.loadingOverlay = true
      let data = {
        idPasien: row.aranr_ap_id,
        regId: row.aranr_id,
        dokterId: row.aranr_dokter_id,
        dokterRaber: row.aranr_dokter_raber_id,
        jenisRawatInap: row.aranr_jenis_ranap
      }
      data.type = 'init-kajian-awal-dokter'
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.loadingOverlay = false
        this.$router.push({
          name: resp.menuKajian,
          params: {
            pageSlug: row.ap_id,
            rmNo: resp.aranrm_id
          },
          query: {
            regId: row.aranr_id
          }
        }).catch(() => {})
      })
    },

    lihatKajianDokter(row) {
      this.$router.push({
        name: this.toMenuKajianAwalDokter(row.aranr_kajian_awal_type),
        params: {
          pageSlug: row.ap_id,
          rmNo: row.kajian_dokter_id
        },
        query: {
          regId: row.aranr_id
        }
      }).catch(() => {})
    }
  },
  filters: {
    date(val) {
      return val ? moment(val).format("D MMM YYYY") : ""
    }
  },
  watch: {
    $route() {
      this.apiGet()

      if (this.isList) {
        this.apiGetResume()
      }

      setTimeout(() => {
        if (this.isList) {
          this.setSlide()
        }
      }, 1000)
    },
    '$route.params.pageSlug': function () {
      this.$set(this, 'filter', {})
    },
    'openPerawatPelaksana': function (v) {
      if (!v) {
        this.apiGet()
      }
    }
  }
}
</script>
